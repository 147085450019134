
.startChallenge {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 79px;
  gap: 24px;
  //align-items: center;
  height: calc(100vh - 120px);
  overflow-y: auto;
  >div:first-of-type{
    display: flex;
    flex-direction: row;
    //align-items: center;
    gap: 63px;
    justify-content: space-between;
    position: relative;
    >div:first-of-type {
      display: flex;
      flex-direction: column;
      //gap: 63px;
      gap: 24px;
      margin-top: 77px;
    };
    .messageRobot{
      position: absolute;
      border-radius: 11.756px;
      background: var(--Main-Lilac-Violet, #7956D8);
      bottom: 60px;
      left: -6%;
      width: 270px;
      height: 125px;
      >p{
        color: #FFF;
        //font-family: Aeonik;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin:0;
        padding: 16px;
      }
    }
    .messageRobot::after {
      content: "";
      position: absolute;
      top: -20px;
      right: 30px;
      width: 32px;
      height: 25px;
      background-color: transparent;
      background-image: url('../../../icons/vector.svg');
      mask-size: contain;
      mask-repeat: no-repeat;
    }
  }
  >div:last-of-type{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 40px;
  }
}
.robbot{
  position: relative;

  >img {
    width: 476px;
    //animation: float 1.5s ease-in-out infinite;
  }
}
//@keyframes float {
//  0% {
//    transform: translateY(0);
//  }
//  50% {
//    transform: translateY(-10px);
//  }
//  100% {
//    transform: translateY(0);
//  }
//}

.challenge {
  max-width: 662px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  >p:first-of-type{
    color: var(--N-9, #17151C);
    //font-family: 'aeonikregular';
    //font-family: "DM Sans";
    font-size: 42px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 0;
    width: 100%;
  }
  >p:last-of-type{
    color: var(--N-9, #17151C);
    //font-family: 'aeonikregular';
    //font-family: "DM Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    margin: 0;
  }
}
.form {
  max-width: 510px;
  min-width: 433px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  >a {
      color: var(--Main-Lilac-Violet, #7956D8);
      text-align: right;
      /* P/Medium */
      //font-family: Aeonik;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px;
      text-decoration: none;
      display: block;
      margin-top: -8px;
  }
  > button {
    position: relative;
    cursor: pointer;
    border-radius: 5px;
    background: var(--Main-Lilac-Violet, #7956D8);
    color: var(--N-0, #FFF);
    //font-family: 'aeonikregular';
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    padding: 13px 16px;
    outline: none;
    border: none;
    >img {
      cursor: pointer;
      width: 24px;
      position: absolute;
      right: 16px;
    }
  }
  >span {
    color: #F14055;
    margin: -9px 2px;
    //font-family: 'aeonikregular';
  }

}
.disabled {
  opacity: .6;
}
.check {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 24px;
  >div{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 12px;
    >img{
      width: 24px;
    }
    >p {
      color: #121037;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      margin: 0;
      //font-family: 'aeonikregular';
    }
  }
}


@media screen and (min-width: 922px) and (max-width: 1320px) {
  .startChallenge {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 24px;
    gap: 24px;
    //align-items: center;
    height: calc(100vh - 120px);
    overflow-y: auto;
    >div:first-of-type{
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      gap: 63px;
      justify-content: space-between;
      position: relative;
      >div:first-of-type {
        display: flex;
        flex-direction: column;
        gap: 63px;
      };
      >div:nth-child(2){
        >.messageRobot{
          position: absolute;
          border-radius: 11.756px;
          background: var(--Main-Lilac-Violet, #7956D8);
          left: -5%;
          bottom: 9%;
          width: 219px;
          //height: -moz-fit-content;
          height: fit-content;
          /* top: -10%; */
          >p{
            font-size: 14px;
          }
        }
        >.messageRobot::after {
          content: "";
          position: absolute;
          top: -20px;
          right: 30px;
          width: 32px;
          height: 25px;
          background-color: transparent;
          background-image: url('../../../icons/vector.svg');
          mask-size: contain;
          mask-repeat: no-repeat;
        }

        >img{
          //max-width: 403px;
          width: 403px;
          //min-width: 300px;
          height: 100%;
        }
      }
    }
    >div:last-of-type{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      gap: 40px;
      >div:last-of-type {
        max-width: 355px;
        width: 100%;
      }
    }
  }
  .challenge {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 16px;
    >p:first-of-type{
      color: var(--N-9, #17151C);
      //font-family: 'aeonikregular';
      //font-family: "DM Sans";
      font-size: 32px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin: 0;
    }
    >p:last-of-type{
      color: var(--N-9, #17151C);
      //font-family: 'aeonikregular';
      //font-family: "DM Sans";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 30px;
      margin: 0;
    }
  }
}
@media screen and (min-width: 768px) and (max-width: 923px){
  .startChallenge {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 24px;
    gap: 24px;
    //align-items: center;
    height: calc(100vh - 120px);
    overflow-y: auto;
    >div:first-of-type{
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      gap: 63px;
      justify-content: space-between;
      position: relative;
      >div:first-of-type {
        display: flex;
        flex-direction: column;
        gap: 63px;
        max-width: 372px;
      };
      >div:nth-child(2){
        >.messageRobot{
          position: absolute;
          border-radius: 11.756px;
          background: var(--Main-Lilac-Violet, #7956D8);
          left: -24%;
          bottom: 2%;
          width: 219px;
          //height: -moz-fit-content;
          height: fit-content;
          /* top: -10%; */
          >p{
            font-size: 14px;
          }
        }
        >.messageRobot::after {
          content: "";
          position: absolute;
          top: -20px;
          right: 30px;
          width: 32px;
          height: 25px;
          background-color: transparent;
          background-image: url('../../../icons/vector.svg');
          mask-size: contain;
          mask-repeat: no-repeat;
        }

        >img{
          //max-width: 403px;
          width: 300px;
          //min-width: 300px;
          height: 100%;
        }
      }
    }
    >div:last-of-type{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      gap: 40px;
      >div:last-of-type {
        max-width: 355px;
        width: 100%;
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .startChallenge {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 0 24px;
    gap: 24px;
    overflow-y: scroll;
    height: calc(100vh - 150px);
    >div:first-of-type{
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
      gap: 22px;
      justify-content: space-between;
      >div:first-of-type {
        display: flex;
        flex-direction: column;
        gap: 48px;
        margin-top: 22px;

      };
      >div:nth-child(2){
        max-width: 227px;
        max-height: 207px;
        align-self: flex-end;
        >.messageRobot{
          position: absolute;
          border-radius: 11.756px;
          background: var(--Main-Lilac-Violet, #7956D8);
          left: -48%;
          bottom: -20%;
          max-width: 219px;
          width: 100%;
          height: fit-content;
          >p{
            font-size: 14px;
          }
        }
        >.messageRobot::after {
          content: "";
          position: absolute;
          top: -20px;
          right: 30px;
          width: 32px;
          height: 25px;
          background-color: transparent;
          background-image: url('../../../icons/vector.svg');
          mask-size: contain;
          mask-repeat: no-repeat;
        }
        >img{
          width: 100%;
          height: 100%;
        }
      }
    }
    >div:last-of-type{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      gap: 32px;
      min-width: 296px;
      //width: 100%;
      >div:last-of-type {
        width: auto;
        padding: 10px;
      }
    }
  }
  .challenge {
    max-width: 490px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    >p:first-of-type{
      color: var(--N-9, #17151C);
      //font-family: 'aeonikregular';
      //font-family: "DM Sans";
      font-size: 32px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin: 0;
    }
    >p:last-of-type{
      color: var(--N-9, #17151C);
      //font-family: 'aeonikregular';
      //font-family: "DM Sans";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 30px;
      margin: 0;
    }
  }
  .form {
    min-width: 0;
    display: flex;
    flex-direction: column;
    gap: 24px;

  }
}
