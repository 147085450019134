.pickList {
  position: relative;
  top: 65px;
  left: 6px;
  left: -1px;
  border-radius: 8px;
  background-color: #FFFFFF;
  box-shadow: 0 16px 32px 4px rgba(0, 0, 0, 0.15);
  padding: 8px;
  margin: 0;
  list-style-type: none;
  max-height: 180px;
  overflow: auto;
  width: 100%;
  box-sizing: border-box;
  >li:not(:first-of-type) {
    margin-top: 8px;
  }
  >li {
    padding: 8px;
    text-align: start;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-radius: 6px;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #17151C;
  }
  >li:hover {
    background-color: #f3f7ff
  }
  color: black;
}

.pickList::-webkit-scrollbar {
  display: none;
}

.simple {
  top: 36px;
}
.selected {
  background-color: #F4F3F7;
}

.groupLists {
  z-index: 1;
  left: -1px;
  border-radius: 4px;
  background-color: white;
  list-style-type: none;
  overflow: auto;
  width: 360px;
  box-sizing: border-box;
    >span {
      color: #7956D8;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 18px;
      padding: 8px;
      margin: 8px;
    } >li {
      padding: 8px;
      margin: 8px;
      text-align: start;
      cursor: pointer;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      }
    >li:hover {
      background: #F4F3F7;
      border-radius: 8px;
    }
    color: #17151C;

}

