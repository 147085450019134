@font-face {
  font-family: 'aeonikregular';
  src: url('fonts/Aeonik-Regular.otf') format('woff'),
  url('fonts/Aeonik-Regular.otf') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'aeonikmedium';
  src: url('fonts/Aeonik-Medium.otf') format('otf'),
  url('fonts/Aeonik-Medium.otf') format('otf');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'aeonikbold';
  src: url('fonts/Aeonik-Bold 2.otf') format('otf'),
  url('fonts/Aeonik-Bold 2.otf') format('otf');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'aeonikregular';
  src: url('fonts/Aeonik-Regular.otf') format('otf'),
  url('fonts/Aeonik-Regular.otf') format('otf');
  font-weight: normal;
  font-style: normal;
}


html {
  height: 100vh;
}

body {
  margin: 0;
  height: 100vh;
}
#root {
  height: 100vh;
  display: flex;
  flex-direction: column;
  /*background-color: #f4f3f7;*/
  overflow: hidden;

}
#modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

#modal >* {
  margin: 16px;
  max-height: calc(100vh - 1em);
}

#modal:empty {
  display: none;
}
