.headerStyles {
  position: relative;
  padding: 15px 24px;
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  > img:last-of-type {
    //position: absolute;
    //right: 20px;
    cursor: pointer;
  }
  >span {
    min-height: 26px;
    max-width: 500px;
    //width: 100%;
  }
}
.fontInGerman {
  font-size: 17px;
}
.hideCloseButton {
  border-bottom: none;
}

.arrowBack {
  position: absolute;
  left: 20px;
  cursor: pointer;
}

.stages {
  display: flex;
  padding-top: 12px;
  padding-bottom: 13px;
}

.stage {
  height: 5px;
  background: #D8AFD6;
  width: 50px;
  border-radius: 2.5px;
}

.disabledStage {
  opacity: 0.3;
}

.stage:not(:last-of-type) {
  margin-right: 8px;
}

.largeHeader {

}

.reportHeader {
  display: flex;
  align-items: center;
  >span {
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
    color: #F14055;
    margin-left: 12px;
  }
}

.checkboxStyles {
  position: absolute;
  left: 16px;
}
