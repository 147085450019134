.payment{
 //height: 100%;
 width: 100%;
 position: relative;
 padding: 70px 0;
 background-color:#cfc2f5;
 height: calc(100vh - 130px);
 overflow-y: auto;

}

.backSection{
 display: flex;
 align-items: center;
 position: fixed;
 top: 20px;
 left: 10px;
 cursor: pointer;
 >img:not(:first-of-type) {
  width: 102px;
  margin-left: 11px;
 }
}
