.settings{
 display: flex;
 flex-direction: row;
 justify-content: space-between;
 position: relative;
 height: calc(100vh - 113px);
 overflow-y: scroll;
 padding: 26px 35px;

 >div:first-of-type {
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 661px;
  width: 100%;
  >h1 {
   margin: 0;
   color: var(--N-9, #17151C);
   /* H2/Bold */
   //font-family: Aeonik;
   font-size: 24px;
   font-style: normal;
   font-weight: 700;
   line-height: 38px; /* 158.333% */
  }
  >div {
   padding: 24px;
   display: flex;
   flex-direction: column;
   justify-content: center;
   gap: 24px;
   border-radius: 9px;
   background: var(--N-1, #F9F9FB);
   >p{
    margin: 0;
    //font-family: Aeonik;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
   }
  }
 }
}

.robbot{
 position: sticky;
 top: 0;
 right: 0;
 >img {
  width: 476px;
 }
}
