.inputWrapper {
  position: relative;

  > .closeIcon {
    position: absolute;
    top: 13px;
    right: 16px;
    cursor: pointer;
  }

  > img:last-of-type {
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;
  }
  > img:first-of-type {
    position: absolute;
    top: 16px;
    right: 32px;
    cursor: pointer;
  }
}

.inputText {
  border-radius: 5px;
  border: 1px solid #DAD6E5;
  width: 100%;
  height: 48px;
  box-sizing: border-box;
  padding: 13px 16px;
  font-size: 16px;
  color: #17151C;
  outline: none;
}
.inputText:hover {
  border: 1px solid #9F81E6;
}
.inputText:focus {
  padding-top: 24px;
  padding-bottom: 8px;
  border: 1px solid #9F81E6;
  outline: none;
}

.inputText:focus ~ .floating-label {
  top: 3px;
  bottom: 10px;
  left: 16px;
  opacity: 1;
  font-size: 14px;
}

.inputText:not(:focus):not(:invalid) ~ .floating-label {
  top: 3px;
  bottom: 10px;
  left: 16px;
  opacity: 1;
  font-size: 14px;
}

.inputText:not(:focus):not(:invalid) {
  padding-top: 24px;
  padding-bottom: 8px;
}

.floating-label {
  position: absolute;
  pointer-events: none;
  left: 16px;
  top: 13px;
  transition: 0.2s ease all;
  color: #A29DB0;
  text-align: start;
}

.errorMessage {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #F14055;
  >img {
    width: 16px;
    height: 16px;
  }
}

.errorMessage > span {
  margin-left: 8px;
  text-align: start;
  max-width: 292px;
}
.worstPromoCode {
  > img {
    width: 20px;
  }
  > span {
    font-size: 13px;
    margin-left: 3px;
  }
}
.marginBottom {
  margin-bottom: 16px;
}

.inputText[type="file" i] {
  background-image: linear-gradient(68deg, #ffb405, #ff0069);
  position: absolute;
  left: -180px;
  top: 20px;
  width: 180px;
  opacity: 0;
  cursor: pointer;
}

.floatingLabelRequired::after {
  content: "*";
  color: #C92E36;
}

.errorMessageMargin {
  margin-bottom: 4px;
}

.invalidInput {
  border-color: #F14055;
}
.inputText:not(:focus){
  padding-top: 24px;
  padding-bottom: 8px;
}

.invalidInput ~ .floating-label {
  top: 3px;
  bottom: 10px;
  left: 16px;
  opacity: 1;
  font-size: 14px;
}

.readOnly {
  background: #F4F3F7 !important;
  color: #A29DB0;
  pointer-events: none;
}

.readOnly[value=""] ~ .floating-label {
  top: 14px !important;
}
//.reactCalendar {
//  position: absolute;
//  box-shadow: 0 16px 32px 4px rgba(0, 0, 0, 0.15);
//  background: #FFFFFF;
//  border-radius: 8px;
//  width: 330px;
//  border: 1px solid #F4F3F7;
//  margin-top:20px;
//  bottom: 63px;
//  right: 3px;
//  :global {
//    .selected {
//      border-radius: 100px;
//      color: #FFFFFF;
//    }
//    .react-calendar__navigation {
//      display: flex;
//      align-items: center;
//      padding: 8px 4px;
//      font-weight: 500;
//      font-size: 14px;
//      line-height: 22px;
//      > button {
//        border:none;
//        background: none;
//        //>span {
//        //  color: #7956D8;
//        //}
//      }
//    }
//    .react-calendar__tile--active {
//      background-color: #FFFFFF !important ;
//      border: 1px solid #7956D8 !important;
//      border-radius: 100%;
//      outline: none;
//      color: #7956D8;
//    }
//    .react-calendar__year-view__months, .react-calendar__decade-view__years{
//      display: flex;
//      flex-wrap: wrap;
//      justify-content: center;
//      padding: 19px 0;
//    }
//    .react-calendar__year-view {
//      > div {
//        >button{
//          background: #FFFFFF;
//          outline: none;
//          border: none;
//          height: 76px;
//          color: #7956D8;
//          flex: 0 0 23% !important;
//          overflow: hidden;
//          margin-inline-end: 0px;
//        }
//        >button:hover{
//          border-radius: 6.247px;
//          background: var(--Main-Lilac-Violet, #7956D8);
//          border: none;
//          color: #FFFFFF;
//        }
//      }
//    }
//
//    .react-calendar__decade-view{
//      > div {
//        >button{
//          background: #FFFFFF;
//          outline: none;
//          border: none;
//          height: 76px;
//          color: #7956D8;
//          flex: 0 0 23% !important;
//          overflow: hidden;
//          margin-inline-end: 0px;
//        }
//        >button:hover{
//          border-radius: 6.247px;
//          background: var(--Main-Lilac-Violet, #7956D8);
//          border: none;
//          color: #FFFFFF;
//        }
//      }
//    }
//
//    .react-calendar__month-view__weekdays {
//      color: #59565C;
//      padding: 10px 12px 10px 18px;
//      font-size: 12px;
//      line-height: 16px;
//      background: #F4F3F7;
//    }
//
//    .react-calendar__navigation__arrow {
//      cursor: pointer;
//    }
//
//
//    .react-calendar__month-view__days {
//      padding-left: 14px;
//      padding-right: 8px;
//      margin-top: 8px;
//      margin-bottom: 8px;
//      display: block  !important;
//
//      >button {
//        //font-family: 'aeonikregular';
//        font-weight: 400;
//        font-size: 14px;
//        line-height: 22px;
//        width: 36px;
//        height: 36px;
//        cursor: pointer;
//        text-align: center;
//        border: none;
//        background: none;
//      }
//      >button:active, >button:hover {
//        cursor: pointer;
//        border-radius: 106px;
//        background: var(--B-6, #7956D8);
//        color: #FFFFFF;
//      }
//      >button:not(:nth-of-type(7n)) {
//        margin-right: 5px;
//      }
//    }
//    abbr {
//      text-decoration: none;
//      //font-family: 'aeonikregular';
//    }
//  }
//
//}
.reactCalendar {
  position: absolute;
  box-shadow: 0 16px 32px 4px rgba(0, 0, 0, 0.15);
  background: #FFFFFF;
  border-radius: 8px;
  width: 330px;
  border: 1px solid #F4F3F7;
  margin-top:20px;
  :global {
    .selected {
      background: #7956D8;
      border-radius: 100px;
      color: #FFFFFF;
    }
    .react-calendar__navigation {
      display: flex;
      align-items: center;
      padding: 8px 4px;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
    }
    .react-calendar__month-view__weekdays {
      color: #59565C;
      background: #F4F3F7;
      padding: 10px 12px 10px 18px;
      font-size: 12px;
      line-height: 16px;
      text-align: center;
    }

    .react-calendar__navigation__arrow {
      cursor: pointer;
    }

    .react-calendar__month-view__days {
      padding-left: 18px;
      padding-right: 12px;
      margin-top: 8px;
      margin-bottom: 8px;
      display: block  !important;
      >button {
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        width: 42.85px;
        height: 36px;
        cursor: pointer;
        text-align: center;
      }

      >button:not(:nth-of-type(7n)) {
        margin-right: 5px;
      }
    }
    abbr {
      text-decoration: none;
    }
  }

}
