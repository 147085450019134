.errorMessage {
  padding: 6px 16px 6px 8px;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #FFF0F0;
  margin-top: 16px;
}

.errorMessage > img {
  margin-right: 8px;
  height: 24px;
}

.errorMessage > span {
  color: #000000;
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  text-align: start;
}

.customError {
  //margin: 0 auto;
  //max-width: 336px;
  //width: 100%;
  margin-top: 0;
}