.phoneNumberField {
  padding: 7px 0 7px 9px;
  display: inline-flex;
  border-radius: 50px;
  border: 1px solid var(--B-2, #ECE0FF);
  background: #FFF;
  width: 468px;
  box-sizing: border-box;
  position: relative;
  align-items: baseline;
}
.phoneNumberField:hover {
  border-radius: 50px;
  border: 1px solid var(--B-4, #9F81E6);
  background: #FFF;
  cursor: pointer;
}
.phoneNumberField > span {
  display: flex;
  margin-right: 4px;
  padding-left: 16px;
  //padding-top: 5px;
  //padding-bottom: 5px;
  border-left: solid 1px #dad6e5;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  color: #17151C
}

.phoneNumberField {
  > input {
    padding: 5px 0;
    border: none;
    font-size: 16px;
    outline: none;
    color: #17151C;
    font-weight: 400;
    line-height: 22px;
  }
  >input::-webkit-outer-spin-button,
  >input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    outline: none;
  }
  > input:focus {
    border: none;
  }
}
  .phoneNumberField > input::placeholder {
    color: #B1ABC2;
  }
  .errorMessage {
    display: flex;
    flex-direction: row;
    margin-top: 4px;
  }
  .errorMessage > span {
    margin-left: 8px;
    text-align: start;
    line-height: 22px;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    color: #F14055;
  }
  .invalidInput {
    border: solid 1px #F14055;
  }

@media screen and (min-width: 768px) and (max-width: 1320px){
  .phoneNumberField {
    height: 40px;
    width: 100%;
    box-sizing: border-box;
    align-items: center;
  }
  .phoneNumberField {
    width: 100%;
    padding: 7px 9px;
    > input {
      width: 100%;
      box-sizing: border-box;
    }
  }
}
@media screen and (max-width: 768px) {
  .phoneNumberField {
    height: 40px;
    width: 100%;
    box-sizing: border-box;
    align-items: center;
  }
  .phoneNumberField {
    width: 100%;
    padding: 7px 9px;
    > input {
      width: 100%;
     box-sizing: border-box;
    }
  }
}
