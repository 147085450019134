.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  display: flex;
  width: 100%;
  height: auto !important;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  background: #F9F9FB;
  //padding: 13px 0;
  > div {
    > p{
      color: #000;
      text-align: center;
      font-family: "Montserrat";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.714px;
    }
  }
}

.disabledFooter{
  opacity: .5;
}
.challengeStep {
  position: fixed;
  left: 0;
  bottom: 0;
  display: flex;
  width: 100%;
  height: 49px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  background: #F9F9FB;
  padding: 13px 0;
}
.inputFooter {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 0 24px;
  gap: 24px;
  //margin-left: auto;
  .group{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    //max-width: 357px;
    gap: 8px;
    width: 100%;
    >div {
      width: auto;
      position: relative;
    }
    >div{
      >img{
        position: absolute;
        right: 10px;
        height: 24px;
        top: 13px;
      }
    }
    >div {
      >input{
        height: 48px;
        border-radius: 50px;
        border: 1px solid #ECE0FF;
        background: #FFF;
        color: #17151C;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        padding: 0 16px;
        outline: none;
      }
      >input[type='number']::-webkit-inner-spin-button {
        -webkit-appearance: none;
      }
    }
  }
  > div:first-of-type {
    display: flex;
    flex-direction: row;
    gap: 8px;
    justify-content: center;
    align-items: center;
    >div{
      >input[type='number']::-webkit-outer-spin-button,
      >input[type='number']::-webkit-inner-spin-button {
        -webkit-appearance: none;
      }
      .bDay{
        color: #3A3542 ;
      }
    }
    > input {
      width: 468px;
      height: 48px;
      border-radius: 50px;
      border: 1px solid #ECE0FF;
      background: #FFF;
      color: #A29DB0;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      padding: 0 16px;
      outline: none;
    }
    >input:hover {
      border: 1px solid  #9F81E6;
    }
    >input:focus {
      color: #3A3542;
      border: 1px solid #9F81E6;
    }
    > div {
      position: relative;
      > input {
        width: 468px;
        height: 48px;
        border-radius: 50px;
        border: 1px solid #ECE0FF;
        background: #FFF;
        color: #A29DB0;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        padding: 0 16px;
        outline: none;
      }
      >input:hover {
        border: 1px solid #9F81E6;
      }
      >input:focus {
        color: #3A3542;
        border: 1px solid #F4F3F7;
      }
      > img {
        position: absolute;
        width: 24px;
        right: 13px;
        top: 13px;
      }
    }
    > .optionHid {
      >.placeholder {
        color: #17151C !important;
      }
      > .select {
        width: 468px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 16px 30px 16px;
        border-radius: 50px;
        border: 1px solid #ECE0FF;
        background-image: url("../../../../icons/arrow.svg");
        background-position: right 0.7rem top 50%;
        background-color: #FFFFFF;
        background-repeat: no-repeat;
        color: #A29DB0;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        outline: none;
        position: relative;
        >option:before {
          content: ">";
          font-size: 20px;
          display: none;
          padding-right: 10px;
          padding-left: 5px;
          color: #fff;
        }
        >option:hover:before {
          display: inline;
        }
      }
      >.option{
        height: 200px;
        overflow: scroll;
        position: absolute;
        bottom: 55px;
        left: 0;
        background-color: #FFFFFF;
        border-radius: 8px;
        box-shadow: 0 16px 32px 4px rgba(0, 0, 0, 0.15);
        display: flex;
        width: 510px;
        margin-bottom: 5px;
        padding: 8px;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
        > div {
          display: flex;
          padding: 8px;
          flex-direction: column;
          align-items: flex-start;
          cursor: pointer;
          width: 100%;
        }
        >div:hover{
          border-radius: 6px;
          background: var(--B-1, #F6F0FF);
        }

      }
      >.option::-webkit-scrollbar{
        height: .4em;
      }
      >.option::-webkit-scrollbar-thumb:horizontal {
        background-color: lightgray;
        border-radius: 10px;
      }
      >.option::-webkit-scrollbar{
        width: .4em;
      }
      >.option::-webkit-scrollbar-thumb:vertical {
        background-color: lightgray;
        border-radius: 10px;
      }
    }
    >input[type='submit'] {
      border-radius: 20px;
      opacity: 0.9;
      background: #F6F0FF;
      width: 42px;
      height: 42px;
      color: #7956D8;
      text-align: center;
      outline: none;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      box-sizing: border-box;
    }
    >input[type='submit']:first-of-type{
      width: 60px;
      box-sizing: border-box;
      padding: 0;
    }
    >input[type='submit']:hover {
      cursor: pointer;
      //border: 1px solid #ECE0FF;
      background-color: #7956D8;
      color: #FFFFFF;
    }
    >input[type ='rate']:hover {
      cursor: pointer;
      border: 1px solid #ECE0FF;
    }
    >input[type='submit']:nth-child(7) {
      width: auto;
      outline: none;
    }
    >input[type='submit']:focus {
      border: 1px solid #9F81E6;
      outline: none;
    }
    >input[type='number']::-webkit-outer-spin-button,
    >input[type='number']::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
      outline: none;
    }
    > button {
      border: none;
      border-radius: 100%;
      background: #7956D8;
      display: flex;
      width: 48px;
      height: 48px;
      padding: 2px;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      cursor: pointer;
      > img {
        width: 20px;
      }
    }
    > button:hover {
      background: #3E2A8C;
    }
    > span {
      .care {
        border-radius: 20px;
        background:  #F6F0FF;
        min-width: 58px;
        height: 42px;
        color: #7956D8 ;
        text-align: center;
        outline: none;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        border: none;
        margin-right: 16px;
      }
      .care:hover {
        cursor: pointer;
        //border: 1px solid #ECE0FF !important;
        background-color: #7956D8;
        color: #FFFFFF;
      }
      .care:focus {
        border: 1px solid #7956D8;
      }
    }
    > .attach {
      padding-left: 40px;
    }
  }

  > button:last-of-type {
    display: flex;
    align-items: center;
    border-radius: 40px;
    background: #FFF;
    height: 48px;
    padding: 0 20px;
    border: none;
    outline: none;
    //position: fixed;
    //right: 24px;
    cursor: pointer;
    > p {
      margin: 0;
      color: var(--B-5, #7956D8);
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
    }
  }
}
.error {
  >div:first-of-type{
    >input {
      border-radius: 50px;
      border: 1px solid #F14055;
      background: #FFF;
      outline: none;
    }
  }
}
.disabled  {
  opacity: .5;
}
.reactCalendar {
  position: absolute;
  box-shadow: 0 16px 32px 4px rgba(0, 0, 0, 0.15);
  background: #FFFFFF;
  border-radius: 8px;
  width: 330px;
  border: 1px solid #F4F3F7;
  margin-top:20px;
  bottom: 63px;
  right: 3px;
  :global {
    .selected {
      border-radius: 100px;
      color: #FFFFFF;
    }
    .react-calendar__navigation {
      display: flex;
      align-items: center;
      padding: 8px 4px;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      > button {
        border:none;
        background: none;
        //>span {
        //  color: #7956D8;
        //}
      }
    }
    .react-calendar__tile--active {
      background-color: #FFFFFF !important ;
      border: 1px solid #7956D8 !important;
      border-radius: 100%;
      outline: none;
      color: #7956D8;
    }
    .react-calendar__year-view__months, .react-calendar__decade-view__years{
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      padding: 19px 0;
    }
    .react-calendar__year-view {
      > div {
        >button{
          background: #FFFFFF;
          outline: none;
          border: none;
          height: 76px;
          color: #7956D8;
          flex: 0 0 23% !important;
          overflow: hidden;
          margin-inline-end: 0px;
        }
        >button:hover{
          border-radius: 6.247px;
          background: var(--Main-Lilac-Violet, #7956D8);
          border: none;
          color: #FFFFFF;
        }
      }
    }

    .react-calendar__decade-view{
      > div {
        >button{
          background: #FFFFFF;
          outline: none;
          border: none;
          height: 76px;
          color: #7956D8;
          flex: 0 0 23% !important;
          overflow: hidden;
          margin-inline-end: 0px;
        }
        >button:hover{
          border-radius: 6.247px;
          background: var(--Main-Lilac-Violet, #7956D8);
          border: none;
          color: #FFFFFF;
        }
      }
    }

    .react-calendar__month-view__weekdays {
      color: #59565C;
      padding: 10px 12px 10px 18px;
      font-size: 12px;
      line-height: 16px;
      background: #F4F3F7;
      text-align: center;
    }

    .react-calendar__navigation__arrow {
      cursor: pointer;
    }


    .react-calendar__month-view__days {
      padding-left: 18px;
      padding-right: 12px;
      margin-top: 8px;
      margin-bottom: 8px;
      display: block  !important;

      >button {
        //font-family: 'aeonikregular';
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        width: 42px;
        height: 42px;
        cursor: pointer;
        text-align: center;
        border: none;
        background: none;
      }
      >button:hover {
        cursor: pointer;
        border-radius: 106px;
        background: var(--B-6, #7956D8);
        color: #FFFFFF;
      }

      >button:not(:nth-of-type(7n)) {
        margin-right: 5px;
        height: 42px;
      }
    }
    abbr {
      text-decoration: none;
      //font-family: 'aeonikregular';
    }
  }

}
.imageUpload {
  position: relative;
  left: 46px;
  margin-left: 8px;
  >input {
    display: none;
    outline: none;
  }
}
.footer{
  display: flex;
  justify-content: center;
  .submit {
    min-width: 300px;
    border-radius: 5px;
    background: #7956D8;
    padding: 10px 16px;
    color: #FFF;
    text-align: center;
    /* P/Medium */
    //font-family: 'aeonikregular';
    //font-family: "DM Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    outline: none;
    border: none;
    cursor: pointer;
  }
}


.disabledBox {
  opacity: .6;
}
.btnDisabled{
  opacity: .5;
  cursor: inherit;
}
.btnDisabled:hover {
  background: #7956D8 !important;
}
@media screen and (min-width: 768px) and (max-width: 1320px){
  .inputFooter {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    gap: 24px;
    padding: 0 24px;

    >button:last-of-type {
      position: relative;
      right: 0;
      height: 40px;
    }
    >div:first-of-type {
      width: 100%;
      .group{
        width: auto;
        >div{
          max-width: 161px;
          width: 100%;
          >input{
            width: 100%;
            box-sizing: border-box;
            height: 40px;
            color: #17151C;
          }
          >img{
            top: 10px;
          }
        }
      }
      > div {
        width: 100%;
        > input {
          box-sizing: border-box;
          width: 100%;
          height: 40px;
          display: flex;
        }
        > img {
          top: 8px
        }
      }
      >input {
        //min-width: 468px;
        box-sizing: border-box;
        width: 100%;
        height: 40px;
      }
      > .optionHid {
        width: 85%;
        box-sizing: border-box;
        height: 40px;
        //padding: 8px 16px;
        > .select {
          width: 100%;
          padding: 8px 16px;
          box-sizing: border-box;
        }
        >.option{
          height: 200px;
          width: 100%;
          bottom: 40px;
        }
      }
      >button {
        width: 40px;
        height: 40px;
      }
    }
  }
}

@media screen and (max-width: 768px){
  .footer {
    //height: 40px;
    padding: 8px;
    width: 100%;
    box-sizing: border-box;
    >div{
      width: 100%;
      >p {
        color: #000;
        text-align: center;
        font-family: 'Montserrat';
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.714px;
        margin: 0;
      }
    }
  }
  .inputFooter {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 0;
    margin-right: 8px;
    >div:first-of-type{
      width: 100%;
      box-sizing: border-box;
      .group{
        width: 100%;
        >div{
          >input{
            height: 40px;
            width: 100%;
            box-sizing: border-box  ;
          }
          >img{
            top: 10px;
          }
        }
      }
      >div{
        width: 100%;

        >input {
          width: 100%;
          height: 40px;
          display: flex;
          padding: 0 0 0 16px;
          box-sizing: border-box;
        }
        > img {
          top: 8px;
        }
      }
      >span {
        justify-content: center;
        display: flex;
      }
      >input, >input[type = 'file'] {
        box-sizing: border-box;
        width: 100%;
        height: 40px;
      }
      >input[type='submit'] {
        width: 40px;
        height: 40px;
      }
      >button{
        position: relative;
        width: 40px;
        height: 40px;
        margin-right: 8px;
      }
      > .optionHid {
        width: 85%;
        box-sizing: border-box;
        height: 40px;
        //padding: 8px 16px;
        > .select {
          //stex avelacnel size
          //max-width: 375px;
          width: 100%;
          padding: 8px 16px;
          box-sizing: border-box;
        }
        >.option{
          height: 200px;
          width: 100%;
          bottom: 40px;
        }
      }
    }
    >button:last-of-type {
      display: none;
    }
  }


}
@media screen and (max-width: 450px){
  .inputFooter {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 0;
    margin-right: 8px;
    >div:first-of-type{
    >button{
      align-self: flex-start;
    }
    }
  }
  .group{
    width: 100%;
    flex-wrap: wrap;
    gap: 0!important;
    >div:first-child{
      flex: 1 1 100%;
    }
    > div:nth-child(2) {
      flex: 1 1 50%;
    }
    > div:nth-child(3) {
      flex: 1 1 50%;
    }
    >div{
      //max-width: 0;
      width: 100%;
      >input{
        width: 100%;
        box-sizing: border-box;
        height: 40px;
        color: #17151C;
      }
      >img{
        top: 10px;
      }
    }
  }
  .reactCalendar {
    width: 100%;
    box-sizing: border-box;
  }
}
