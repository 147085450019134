.setPassword{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  > div:first-of-type {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    max-width: 564px;
    width: 100%;
    > h1 {
      color: #17151C;
      font-size: 32px;
      font-style: normal;
      font-weight: 700;
      line-height: 49px;
      margin: 0 0 16px;
    }
    >p{
      color: #17151C;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 30px;
      margin: 0 0 32px;
    }

  }
}
.form {
  min-width: 433px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  > input {
    border-radius: 5px;
    border: 1px solid var(--N-3, #DAD6E5);
    background: var(--N-0, #FFF);
    color: var(--N-5, #A29DB0);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    padding: 13px 16px;
    outline: none;

  }
  > button {
    position: relative;
    cursor: pointer;
    border-radius: 5px;
    background: var(--Main-Lilac-Violet, #7956D8);
    color: var(--N-0, #FFF);
    //font-family: 'aeonikregular';
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    padding: 13px 16px;
    outline: none;
    border: none;
    cursor: pointer;
    >img {
      cursor: pointer;
      width: 24px;
      position: absolute;
      right: 16px;
    }
  }
  >span {
    color: #F14055;
    margin: -9px 2px;
    //font-family: 'aeonikregular';
  }

}
.disabled {
  opacity: .6;
}
.disable {
  > input[type='email']{
    opacity: .6;
    background:  #F4F3F7
  }
}
.password{
  position: relative;
  > input {
    border-radius: 5px;
    border: 1px solid var(--N-3, #DAD6E5);
    background: var(--N-0, #FFF);
    color: var(--N-5, #A29DB0);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    padding: 13px 16px;
    outline: none;
    width: 433px;
  }
  > input:focus {
    padding-top: 24px;
    padding-bottom: 8px;
  }

  > input:focus ~ .floating-label {
    top: 3px;
    bottom: 10px;
    left: 16px;
    opacity: 1;
    font-size: 14px;
  }

  > input:not(:focus):not(:invalid) ~ .floating-label {
    top: 3px;
    bottom: 10px;
    left: 16px;
    opacity: 1;
    font-size: 14px;
  }

  > input:not(:focus):not(:invalid) {
    padding-top: 24px;
    padding-bottom: 8px;
  }

  .floating-label {
    position: absolute;
    pointer-events: none;
    left: 16px;
    top: 13px;
    transition: 0.2s ease all;
    color: #A29DB0;
    text-align: start;
  }
}
@media screen and (min-width: 768px) and (max-width: 1320px){
  .setPassword{
    margin: 130px auto;
    > div:first-of-type {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      max-width: 490px;
      width: 100%;
      > h1 {
        color: #17151C;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 49px;
        margin: 0 0 16px;
      }
      >p{
        color: #17151C;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 30px;
        margin: 0 0 32px;
      }
    }
    >div:last-of-type{
      max-width: 217px;
      width: 100%;
      display: flex;
      align-self: flex-start;
      >img{
        width: 100%;
      }
    }
  }
}
@media screen and (max-width: 768px){
  .setPassword{
    margin: 32px 16px;
    flex-direction: column-reverse;
    justify-content: flex-end;
    align-items: center;
    height: 100vh;
    overflow: scroll;
    > div:first-of-type {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      max-width: 335px;
      width: 100%;
      > h1 {
        color: #17151C;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 49px;
        margin: 0 0 16px;
      }
      >p{
        color: #17151C;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 30px;
        margin: 0 0 32px;
      }
    }
    >div:last-of-type{
      max-width: 217px;
      width: 100%;
      display: flex;
      align-self: center;
      >img{
        width: 100%;
      }
    }
  }
  .form{
    min-width: 335px;
    width: 100%;
  }
}
