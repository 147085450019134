.chooseData{
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: calc(100vh - 120px);
  overflow-y: scroll;
  position: relative;
  >div:last-of-type{
    >img{
      width: 269px;
      position: absolute;
      right: 85px;
      //top: 76px;
    }
  }
}
.chooseCards {
  display: flex;
  flex-direction: column;
  //justify-content: center;
  align-items: center;
  gap: 34px;
  margin-top: 48px;
  height: calc(100vh - 168px);
  overflow-y: scroll;
  >div:first-of-type{
    width: 740px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    >div{
      display: none;
    }
    >p{
      margin: 0;
    }
    >p:first-of-type{
      color: var(--N-8, #3A3542);
      text-align: center;
      /* H2/Bold */
      //font-family: 'aeonikregular';
      //font-family: "DM Sans";
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 38px;
    }
    >p:last-of-type {
      color: var(--Neutral-600, #6F6C90);
      text-align: center;
      /* P/Regular */
      //font-family: 'aeonikregular';
      //font-family: "DM Sans";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
    }
  }
}
.chooseCards::-webkit-scrollbar {
display: none;
}
.cards {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: baseline;
  gap: 21px;
  >div{
    display: flex;
    flex-direction: column;
    width: 337px;
    gap: 11px;
    padding: 30px;
    border-radius: 17.005px;
    border: 0.709px solid var(--Neutral-300, #EFF0F6);
    background: var(--Neutral-100, #FFF);
    box-shadow: 0px 1.417px 8.502px 0px rgba(20, 20, 43, 0.08);
  }
  >.column{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    gap: 16px;
  }
  >div:nth-child(odd){
  border-radius: 17.005px;
  border: 0.709px solid var(--Neutral-300, #EFF0F6);
  background: var(--Neutral-100, #FFF);
  box-shadow: 0px 1.417px 8.502px 0px rgba(20, 20, 43, 0.08);
    >div:first-of-type{
      display: flex;
      flex-direction: column;
      gap: 16px;
      >div:first-of-type{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        gap: 16px;
        >p{
          margin: 0;
          color: var(--Neutral-800, #170F49);
          //font-feature-settings: 'clig' off, 'liga' off;
          font-family: "DM Sans";
          font-size: 24px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }
      }
      > .yearly {
        display: flex;
        color: var(--Neutral-800, #170F49);
        //font-feature-settings: 'clig' off, 'liga' off;
        font-family: "DM Sans";
        font-size: 38.261px;
        font-style: normal;
        font-weight: 700;
        line-height: 46.763px;
        > span:last-of-type{
          color: var(--Neutral-600, #6F6C90);
          font-family: "DM Sans";
          font-size: 14.171px;
          font-style: normal;
          font-weight: 500;
          line-height: 15.588px;
          height: 16px;
          margin-top: 10px;
        }
      }
      > .included {
        color: var(--Neutral-800, #170F49);
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: "DM Sans";
        font-size: 12.754px;
        font-style: normal;
        font-weight: 700;
        line-height: 14.171px;
      }
      >.CheckLists{
        >span{
          > p{
            margin: 0;
            color: #170F49;
            font-feature-settings: 'clig' off, 'liga' off;
            font-family: "DM Sans";
            font-size: 12.754px;
            font-style: normal;
            font-weight: 400;
            line-height: 14.171px;
          }
        }
      }
    }

    > .cardsBtn {
      >a {
        text-decoration: none;
      }
      button{
        cursor: pointer;
        display: flex;
        justify-content: center;
        min-width: 227px;
        height: 34px;
        padding: 8px 29px;
        color: var(--N-0, #FFF);
        text-align: center;
        //font-family: 'aeonikregular';
        //font-family: "DM Sans";
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        border-radius: 5px;
        background: var(--B-6, #7956D8);
        outline: none;
        border: none;
      }
    }
  }
  >div:nth-child(even){
  border-radius: 17.005px;
  background: var(--Main-Lilac-Violet, #7956D8);
  box-shadow: 0px 1.417px 8.502px 0px rgba(20, 20, 43, 0.08);
>div:first-of-type{
  display: flex;
  flex-direction: column;
  gap: 16px;
  >div:first-of-type{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;
    >p{
      color: var(--Neutral-100, #FFF);
      //font-feature-settings: 'clig' off, 'liga' off;
      font-family: "DM Sans";
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      margin: 0;
    }
    >span {
      position: relative;
      right: -20px;
      bottom: 10px;
      border-radius: 7.085px;
      background: rgba(255, 255, 255, 0.20);
      color: var(--Neutral-100, #FFF);
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: "DM Sans";
      font-size: 9.919px;
      font-style: normal;
      font-weight: 400;
      line-height: 9.919px;
      padding: 8px 17px;
    }
  }
  > .yearly {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: var(--Neutral-100, #FFF);
    //font-feature-settings: 'clig' off, 'liga' off;
    font-family: "DM Sans";
    font-size: 38.261px;
    font-style: normal;
    font-weight: 700;
    line-height: 46.763px;
    >div:first-of-type{
      display: flex;
      >span:first-of-type{
        color: var(--Neutral-100, #FFF);
        font-feature-settings: 'liga' off, 'clig' off;
        //font-family: 'Aeonik';
        font-size: 38.261px;
        font-style: normal;
        font-weight: 700;
        line-height: 46.763px; /* 122.222% */
      }
      > span:last-of-type{
        color: var(--Neutral-400, #D9DBE9);
        font-family: "DM Sans";
        font-size: 14.171px;
        font-style: normal;
        font-weight: 500;
        line-height: 15.588px;
        height: 16px;
        margin-top: 10px;
      }
    }
    >div:last-of-type{
      color: var(--Neutral-400, #D9DBE9);
      font-feature-settings: 'liga' off, 'clig' off;
      //font-family: 'Aeonik';
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 15.588px; /* 129.898% */
    }

  }
  > .included {
    color: var(--Neutral-100, #FFF);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "DM Sans";
    font-size: 12.754px;
    font-style: normal;
    font-weight: 700;
    line-height: 14.171px;
  }
  >.CheckLists{
    >span {
      >p{
        margin: 0;
        color: #D9DBE9;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: "DM Sans";
        font-size: 12.754px;
        font-style: normal;
        font-weight: 400;
        line-height: 14.171px;
      }
    }
  }
}

    > .cardsBtn {
      >a {
        text-decoration: none;
      }
      button{
        cursor: pointer;
        display: flex;
        width: 227px;
        height: 34px;
        padding: 8px 29px;
        justify-content: center;
        color: var(--Main-Lilac-Violet, #7956D8);
        text-align: center;
        //font-family: 'aeonikregular';
        //font-family: "DM Sans";
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        border-radius: 5px;
        background: var(--N-0, #FFF);
        outline: none;
        border: none;
      }
    }
  }
}
.CheckLists {
  display: flex;
  flex-direction: column;
  gap: 11px;
   > span {
     display: flex;
     flex-direction: row;
     justify-content: flex-start;
     align-items: center;
     gap: 11px;
     > p{
       margin: 0;
     }
   }
}


.yearly {
  color: var(--Neutral-800, #170F49);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: "DM Sans";
  font-size: 38.261px;
  font-style: normal;
  font-weight: 700;
  line-height: 46.763px;
  > span{
    color: var(--Neutral-600, #6F6C90);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "DM Sans";
    font-size: 14.171px;
    font-style: normal;
    font-weight: 500;
    line-height: 15.588px;
  }
}
.disableBtn {
  opacity: .6;
}

@media screen and (max-width: 1320px){
  .chooseData{
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-end;
    align-items: center;
    height: calc(100vh - 120px);
    overflow-y: scroll;
    >div:last-of-type{
     display: none;
    }
  }
  .chooseCards {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 24px;
    margin-top: 0;
    height: calc(100vh - 120px);
    overflow-y: scroll;
    >div:first-of-type{
     >div{
       display: flex;
       >img{
             width: 269px;
           }
     }
    }
  }
  .chooseCards::-webkit-scrollbar{
    display: none;
  }
  .cards {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-items: baseline;
    gap: 21px;

  }
}
@media screen and (max-width: 768px){
  .chooseData{
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-end;
    align-items: center;
    margin-top: 0;
    height: 100%;
    overflow-y: scroll;
    >div:last-of-type{
      display: none;
      top: 0;
      height: 217px;

    }
  }
  .chooseCards {
    display: flex;
    flex-direction: column;
    //justify-content: flex-end;
    align-items: center;
    gap: 24px;
    height: calc(100vh - 120px);
    //overflow-y: scroll;
    >div:first-of-type{
      width: 335px;
      display: flex;
      flex-direction: column;
      gap: 16px;
      justify-content: center;
      align-items: center;
      >div {
        display: flex;
        width: 269px;
        >img{
          width: 100%;
          top: 0;
          height: 100%;
        }
      }
      > p:first-of-type,> p:last-of-type {
        text-align: start;
      };
    }
  }
  .cards {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: baseline;
    gap: 21px;

  }
}
