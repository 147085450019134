.modalComponent {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px 100px 24px 100px;
  >div:last-of-type {
    width: max-content;
    display: flex;
    justify-content: unset;
    align-items: center;
    gap: 26px;
    margin-top: 24px;

  }
  > span {
   max-width: max-content !important;
    .care {
      border-radius: 20px;
      background:  #F6F0FF;
      min-width: 58px;
      height: 42px;
      color: #7956D8 ;
      text-align: center;
      outline: none;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      border: none;
      margin-right: 16px;
    }
    .care:last-of-type{
      margin-right: 0;

    }
    .care:hover {
      cursor: pointer;
      //border: 1px solid #ECE0FF !important;
      background-color: #7956D8;
      color: #FFFFFF;
    }
    .care:focus {
      border: 1px solid #7956D8;
    }
  }
}
.reactCalendarActive {
  position: fixed;
  background: #FFFFFF;
  border-radius: 8px;
  width: 330px;
  border: 2px solid #F4F3F7;
  z-index: 9999;
  //margin-top:20px;
  //bottom: 63px;
  //right: 3px;
  :global {
    .selected {
      border-radius: 100px;
      color: #FFFFFF;
    }
    .react-calendar__navigation {
      display: flex;
      align-items: center;
      padding: 8px 4px;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      > button {
        border:none;
        background: none;
        //>span {
        //  color: #7956D8;
        //}
      }
    }
    .react-calendar__tile--active {
      background-color: #FFFFFF !important ;
      border: 1px solid #7956D8 !important;
      border-radius: 100%;
      outline: none;
      color: #7956D8;
    }
    .react-calendar__year-view__months, .react-calendar__decade-view__years{
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      padding: 19px 0;
    }
    .react-calendar__year-view {
      > div {
        >button{
          background: #FFFFFF;
          outline: none;
          border: none;
          height: 76px;
          color: #7956D8;
          flex: 0 0 23% !important;
          overflow: hidden;
          margin-inline-end: 0px;
        }
        >button:hover{
          border-radius: 6.247px;
          background: var(--Main-Lilac-Violet, #7956D8);
          border: none;
          color: #FFFFFF;
        }
      }
    }

    .react-calendar__decade-view{
      > div {
        >button{
          background: #FFFFFF;
          outline: none;
          border: none;
          height: 76px;
          color: #7956D8;
          flex: 0 0 23% !important;
          overflow: hidden;
          margin-inline-end: 0px;
        }
        >button:hover{
          border-radius: 6.247px;
          background: var(--Main-Lilac-Violet, #7956D8);
          border: none;
          color: #FFFFFF;
        }
      }
    }

    .react-calendar__month-view__weekdays {
      color: #59565C;
      padding: 10px 12px 10px 18px;
      font-size: 12px;
      line-height: 16px;
      background: #F4F3F7;
      text-align: center;
    }

    .react-calendar__navigation__arrow {
      cursor: pointer;
    }


    .react-calendar__month-view__days {
      padding-left: 18px;
      padding-right: 12px;
      margin-top: 8px;
      margin-bottom: 8px;
      display: block  !important;

      >button {
        //font-family: 'aeonikregular';
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        width: 42.85px;
        height: 36px;
        cursor: pointer;
        text-align: center;
        border: none;
        background: none;
      }
      >button:active {
        cursor: pointer;
        border-radius: 106px;
        background: var(--B-6, #7956D8);
        color: #FFFFFF;
      }
      >button:not(:nth-of-type(7n)) {
        margin-right: 5px;
      }
    }
    abbr {
      text-decoration: none;
      //font-family: 'aeonikregular';
    }
  }
}
.reactCalendar {
  position: relative;
  background: #FFFFFF;
  border-radius: 8px;
  width: 330px;
  border: 2px solid #F4F3F7;
  z-index: 9999;
  //margin-top:20px;
  //bottom: 63px;
  //right: 3px;
  :global {
    .selected {
      border-radius: 100px;
      color: #FFFFFF;
    }
    .react-calendar__navigation {
      display: flex;
      align-items: center;
      padding: 8px 4px;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      > button {
        border:none;
        background: none;
        //>span {
        //  color: #7956D8;
        //}
      }
    }
    .react-calendar__tile--active {
      background-color: #FFFFFF !important ;
      border: 1px solid #7956D8 !important;
      border-radius: 100%;
      outline: none;
      color: #7956D8;
    }
    .react-calendar__year-view__months, .react-calendar__decade-view__years{
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      padding: 19px 0;
    }
    .react-calendar__year-view {
      > div {
        >button{
          background: #FFFFFF;
          outline: none;
          border: none;
          height: 76px;
          color: #7956D8;
          flex: 0 0 23% !important;
          overflow: hidden;
          margin-inline-end: 0px;
        }
        >button:hover{
          border-radius: 6.247px;
          background: var(--Main-Lilac-Violet, #7956D8);
          border: none;
          color: #FFFFFF;
        }
      }
    }

    .react-calendar__decade-view{
      > div {
        >button{
          background: #FFFFFF;
          outline: none;
          border: none;
          height: 76px;
          color: #7956D8;
          flex: 0 0 23% !important;
          overflow: hidden;
          margin-inline-end: 0px;
        }
        >button:hover{
          border-radius: 6.247px;
          background: var(--Main-Lilac-Violet, #7956D8);
          border: none;
          color: #FFFFFF;
        }
      }
    }

    .react-calendar__month-view__weekdays {
      color: #59565C;
      padding: 10px 12px 10px 18px;
      font-size: 12px;
      line-height: 16px;
      background: #F4F3F7;
      text-align: center;
    }

    .react-calendar__navigation__arrow {
      cursor: pointer;
    }


    .react-calendar__month-view__days {
      padding-left: 18px;
      padding-right: 12px;
      margin-top: 8px;
      margin-bottom: 8px;
      display: block  !important;

      >button {
        //font-family: 'aeonikregular';
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        width: 42.85px;
        height: 36px;
        cursor: pointer;
        text-align: center;
        border: none;
        background: none;
      }
      >button:active {
        cursor: pointer;
        border-radius: 106px;
        background: var(--B-6, #7956D8);
        color: #FFFFFF;
      }
      >button:not(:nth-of-type(7n)) {
        margin-right: 5px;
        height: 42px;
      }
    }
    abbr {
      text-decoration: none;
      //font-family: 'aeonikregular';
    }
  }

}
.odd  {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: center;
  >img {
    //width: 48px;
    height: 48px;
  }
  >.checkbox {
    display: flex;
    align-items: center;
    gap: 8px;
    width: 492px;
    color: #17151C;
    //font-family: 'aeonikregular';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    border-radius: 10px ;
    background: #F6F0FF;
    > .checkboxStyles {
      //align-self: flex-start;
      cursor: pointer;
    }
  }
  >p {
    width: 396px;
    border-radius: 10px 10px 10px 0;
    background: #F6F0FF;
    padding: 16px;
    text-align: justify;
    //font-family: 'aeonikregular';
    margin: 0;
    > div {
      > p {
        margin: 0;
        color:  #7956D8;
        //font-family: 'aeonikregular';
        //font-family: "DM Sans";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
      };
    }
  }
}
.rate {
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: center;
  align-items: center;
  >input[type='submit'] {
    border-radius: 20px;
    opacity: 0.9;
    background: #F6F0FF;
    width: 42px;
    height: 42px;
    color: #7956D8;
    text-align: center;
    outline: none;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    border: none;
  }
  >input[type='submit']:first-of-type{
    width: 60px;
    box-sizing: border-box;
  }
  >input[type='submit']:hover {
    cursor: pointer;
    border: 1px solid #ECE0FF;
  }
  >input[type ='rate']:hover {
    cursor: pointer;
    border: 1px solid #ECE0FF;
  }
  >input[type='submit']:nth-child(7) {
    width: auto;
    outline: none;
  }
  >input[type='submit']:focus {
    border: 1px solid #9F81E6;
    outline: none;
  }
}
.optionHid {
  >.placeholder{
    color: #A29DB0;
  }
  >.select{
    width: 468px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 16px 30px 16px;
    border-radius: 50px;
    border: 1px solid #ECE0FF;
    //background-image: url("../../../../icons/smallArrow.svg");
    background-position: right 0.7rem top 50%;
    background-color: #FFFFFF;
    background-repeat: no-repeat;
    color: var(--N-9, #17151C);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    outline: none;
    position: relative;
    >option:before {
      content: ">";
      font-size: 20px;
      display: none;
      padding-right: 10px;
      padding-left: 5px;
      color: #fff;
    }
    > img {
      position: absolute;
      right: 20px;
      top: 20px;
    }
    >option:hover:before {
      display: inline;
    }
  }
  >.imgRotate {
    >img{
      transform: rotate(180deg);
    }
  }
  .option {
    height: 200px;
    overflow: scroll;
    position: relative;
    top: 5px;
    left: 0;
    background-color: #FFFFFF;
    border-radius: 8px;
    box-shadow: 0 16px 32px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    width: 510px;
    margin-bottom: 5px;
    padding: 8px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    text-align: justify;
    > div {
      display: flex;
      padding: 8px;
      flex-direction: column;
      align-items: flex-start;
      cursor: pointer;
      width: 100%;
    }
    >div:hover{
      border-radius: 6px;
      background: var(--B-1, #F6F0FF);
    }

  }
  >.option::-webkit-scrollbar{
    height: .4em;
  }
  >.option::-webkit-scrollbar-thumb:horizontal {
    background-color: lightgray;
    border-radius: 10px;
  }
  >.option::-webkit-scrollbar{
    width: .4em;
  }
  >.option::-webkit-scrollbar-thumb:vertical {
    background-color: lightgray;
    border-radius: 10px;
  }
}
.group{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  //max-width: 357px;
  gap: 10px;
  width: 100%;
  >div {
    width: auto;
    position: relative;
  }
  >div{
    >img{
      position: absolute;
      right: 10px;
      height: 24px;
      top: 13px;
    }
  }
  >div {
    >input{
      height: 48px;
      border-radius: 50px;
      border: 1px solid #ECE0FF;
      background: #FFF;
      color: #17151C;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      padding: 0 16px;
      outline: none;
      max-width: 150px;
    }
    >input[type='number']::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
  }
}
@media screen and (max-width: 768px){
  .modalComponent {
    padding: 24px;
    >p {
      word-break: break-all;
      width: 100%;
      box-sizing: border-box;
    }
  }
  .group{
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 480px){
  .reactCalendar{
    left: 50%;
    transform: translate(-50%, 0);
  }
}
