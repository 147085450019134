.pickListWrapper {
  position: absolute;
  top: 64px;
  right: 0;
  width: 100%;
  min-width: 217px;
  margin: 0;
  padding: 8px;
  box-sizing: border-box;
  list-style-type: none;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 16px 32px 4px rgba(0, 0, 0, 0.15);
  cursor: default;
  z-index: 1;
  >li {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding : 8px;
    >img {
      margin-right: 12px;
      height: 24px;
    }
    >a {
      /* S/Regular */
      //font-family: Aeonik;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
      color: #17151C;
      text-decoration: none;
      cursor: pointer;
    }
  }
  >li:last-of-type {
    //padding-top: 8px;
    //border-top: 1px solid #DAD6E5;
  }
  >li:last-of-type {
    //padding-top: 8px;
    //padding-bottom: 0;
    //cursor: pointer;
    > a {
      //cursor: pointer;
    }
  }
}

