.pickList {
  position: absolute;
  border-radius: 4px;
  box-shadow: 0 0 0 1px #dad6e5;
  background-color: #ffffff;
  bottom: 55px;
  left: 0;
  box-sizing: border-box;
  text-align: left;
  //height: 233px;
  max-height: 130px;
  overflow-y: auto;
  z-index: 101;
  > div {
    padding: 12px 16px;
    display: flex;
    align-items: center;
  }

  >div:hover {
    background-color: #F6F0FF;
  }

  >div >img {
    width: 24px;
    height: 16px;
    margin-right: 12px;
  }
}
.pickList::-webkit-scrollbar {
  display: none;
}

