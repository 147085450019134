.toastNotification {
  width: 400px;
  box-sizing: border-box;
  background: #FFFFFF;
  box-shadow: 0 16px 32px 4px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  position: absolute;
  display: flex;
  padding: 16px 12px;
  bottom: 24px;
  right: 24px;
  z-index: 9999;
  align-items: start;
}

.text {
  margin-left: 8px;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.tittle {
  color: #000000;
  font-size: 16px;
  line-height: 22px;
  font-weight: bold;
  flex: 1;
}

.body {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #59565C;
}

.closeButton {
  width: 20px;
}