.header {
  display: flex;
  align-items: center;
  //padding-left: 30px;
  //padding-right: 30px;
  position: relative;
  padding: 0 24px;
  border-bottom: 1px solid var(--N-2, #F4F3F7);
  background: #FFF;
  height: 60px;
  > span {
    width: 145px;
    height: 44px;
    margin-right: 90px;
    cursor: pointer;
    background: url("../../../../icons/logo.svg") no-repeat;
  }
  >div {
    display: flex;
    align-items: center;
    justify-content: end;
    margin-left: auto;
    height: 100%;
    border-left: 1px solid #DAD6E5;
    gap: 24px;
    >div:nth-of-type(1) {
      margin-left: 24px;
    }
    //>div:nth-of-type(2) {
    //  position: relative;
    //}

  }
}
.status {
  display: flex;
  background-color: red;
}
//.userType {
//  position: absolute;
//  padding: 1px 6px;
//  background-color: #7956D8;
//  border-radius: 3px;
//  color: #FFF;
//  //font-family: Aeonik;
//  font-size: 10px;
//  font-weight: 400;
//  line-height: normal;
//  left: 4px;
//  bottom: -3.6px;
//}

.active{
  padding-top: 24px;
  padding-bottom: 21px;
  border-bottom: 3px solid #7956D8;
}
@media screen and (max-width: 1320px){
  .sum {
    >div:first-of-type {
      margin: 0 auto;
      display: flex;
      //flex-direction: column;
      padding: 2px 16px;
      align-items: center;
      height: 34px;
      gap: 8px;
      border-radius: 9px;
      background: #F4F3F7;
      position: relative;
      flex-direction: column;
      >span{
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 100%;
        width: 100%;
        margin-left: 0;
        gap: 8px;
        > p {
          color: #17151C;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 30px;
          margin: 0;
        }
      }
      >div{
        background: #FFFFFF;
        display: flex;
        position: fixed;
        left: 0;
        top: 61px;
        width: 100vw ;
        height: calc(100vh - 60px);
        z-index: 10000;
        margin: 0;
        gap: 0;
        overflow-y: scroll;
        >div:last-of-type{
          margin-bottom: 20px;
        }
        >div {
          display: flex;
          width: 100%;
          //height: 100%;
          background: #FFFFFF;
          >div{
            padding: 24px 16px 0;
            width: 100%;
            box-sizing: border-box;
            > div:first-of-type{
              display: flex;
              justify-content: space-between;
              border-radius: 5px;
              /* border: 2px solid #F4F3F7; */
              background: #F4F3F7;
              padding: 0 16px;
            }
            > div:last-of-type{
              display: flex;
              justify-content: space-between;
              //border-radius: 5px;
              //border:  solid #F4F3F7;
              background: #F4F3F7;
              padding: 0 16px;
              //border-top: none;
            }
            >div{
              >ul{
                display: flex;
                flex-direction: column;
                gap: 8px;
                width: 100%;
                text-decoration: none;
                padding-left: 0;
                > li {
                  width: 100%;
                  display: flex;
                  justify-content: space-between;
                  >p:first-of-type{
                    //display: flex;
                    flex-direction: column;
                    gap: 8px;
                    color: #59565C;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 22px;
                    margin: 0;
                    width: 400px;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    >p{
                      margin: 0;
                      white-space: nowrap;
                      text-overflow: ellipsis;
                      overflow: hidden;
                      width: 300px;
                    }
                  }
                  >p:last-of-type{
                    display: flex;
                    flex-direction: row;
                    color: #59565C;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 22px;
                    margin: 0;

                    >div{
                      display: flex;
                      flex-direction: column;
                    }
                    >img{
                      width: 24px;
                    }
                    >p{
                      margin: 0;
                     text-align: end;
                      white-space: nowrap;
                      text-overflow: ellipsis;
                      overflow: hidden;
                      width: 300px;
                      > p {
                        display: flex;
                        flex-direction: column;
                        margin: 0;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        width: 300px;
                        text-align: end;
                        gap: 8px;
                        > p{
                          margin: 0;
                        }
                      }
                    }

                  }
                };
              }
            }
          }
        }
      }
      >div::-webkit-scrollbar {
        width: .3em;
        margin-left: 10px;
        background-clip: padding-box;
      }
      >div::-webkit-scrollbar-thumb {
        background-color: #bdbaba;
        border-radius: 10px;
        background-clip: padding-box;
      }
    }
    > span {
      margin-right: 0;
    }
    > div:last-of-type {
      margin-left: 0;
      border: none;
      display: contents;
      >div:first-of-type{
        margin-left: 0;
      }
    }
  }
}
@media screen and (max-width: 768px){
  .header{
    >span{
      width: 40px;
      background: url("../../../../icons/smallFlexxiLogo.svg") no-repeat;
    }
  }
}

