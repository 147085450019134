.forgotPassword {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 79px;
  gap: 24px;
  //align-items: center;
  height: calc(100vh - 120px);
  overflow-y: auto;
  >div:first-of-type{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 63px;
    justify-content: space-between;
    >div:first-of-type {
      display: flex;
      flex-direction: column;
      gap: 24px;
    };
  }
  >div:last-of-type{
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: center;
    gap: 40px;
  }
}
.robbot{
  >img {
    width: 476px;
    //animation: float 1.5s ease-in-out infinite;
  }
}
//@keyframes float {
//  0% {
//    transform: translateY(0);
//  }
//  50% {
//    transform: translateY(-10px);
//  }
//  100% {
//    transform: translateY(0);
//  }
//}

.challenge {
  max-width: 510px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  >p:first-of-type{
    color: var(--N-9, #17151C);
    //font-family: 'aeonikregular';
    //font-family: "DM Sans";
    font-size: 48px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 0;
  }
  >p:last-of-type{
    color: var(--N-9, #17151C);
    //font-family: 'aeonikregular';
    //font-family: "DM Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    margin: 0;
  }
}
.form {
  min-width: 433px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  >a {
    color: var(--Main-Lilac-Violet, #7956D8);
    text-align: right;
    /* P/Medium */
    //font-family: Aeonik;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    display: block;
  }

  > button:first-of-type {
    position: relative;
    cursor: pointer;
    border-radius: 5px;
    background: var(--Main-Lilac-Violet, #7956D8);
    color: var(--N-0, #FFF);
    //font-family: 'aeonikregular';
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    padding: 13px 16px;
    outline: none;
    border: none;
    >img {
      cursor: pointer;
      width: 24px;
      position: absolute;
      right: 16px;
    }
  }
  >span {
    color: #F14055;
    margin: -9px 2px;
    //font-family: 'aeonikregular';
  }

}
.disabled {
  opacity: .6;
}
.errorMessage {
  display: flex;
  flex-direction: row;
  margin-top: 4px;

}
.errorMessage > span {
  margin-left: 8px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #F14055;
  text-align: start
}
.enterEmailForm {
  >a {
    display: block;
    margin-top: 8px;
    text-align: center;
    text-decoration: none;
  }
}
.enterCodeForm {
  >a {
    display: block;
    margin-top: 8px;
    text-align: center;
    text-decoration: none;
  }
  > button:last-of-type {
    margin-top: 8px;
  }

}
.confirmCodeField {
  border-radius: 5px;
  border: solid 1px #dad6e5;
  width: 100%;
  padding:  7px 75px 7px 78px;
  text-align: center;
  letter-spacing: 14px;
  font-size: 20px;
  line-height: 32px;
  font-weight: 400;
  box-sizing: border-box;
  color: #17151C;
}
.confirmCodeField:focus {
  border-width: 1px;
}

.invalidInput {
  border: solid 1px #F14055;
}

.newPasswordForm {
  gap: 32px
}

.check {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 24px;
  >div{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 12px;
    >img{
      width: 24px;
    }
    >p {
      color: #121037;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      margin: 0;
      //font-family: 'aeonikregular';
    }
  }
}
.contactPerson {
  width: 430px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  border-radius: 26.924px;
  border: 1px solid var(--N-3, #DAD6E5);
  background: var(--N-0, #FFF);
  padding: 22px 40px;
  >p {
    margin: 0;
    color: #121037;
    //font-family: "aeonikregular";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  >div {
    display: flex;
    flex-direction: row;
    gap: 32px;
    >div:last-of-type{
      display: flex;
      flex-direction: column;
      gap: 11px;
      >p{
        margin:0;
      }
      >p:nth-of-type(1){
        color: #121037;
        //font-family: "aeonikregular";
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-bottom: -7px;
      }
      >p:nth-of-type(2) {
        color: #121037;
        //font-family: 'aeonikregular';
        //font-family: "DM Sans";
        font-size: 12px;
        font-style: normal;
        font-weight: 250;
        line-height: normal;
      }
      >p:nth-of-type(3) {
        color: var(--Main-Lilac-Violet, #7956D8);
        //font-family: 'aeonikregular';
        //font-family: "DM Sans";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      >p:nth-of-type(4) {
        color: #121037;
        //font-family: 'aeonikregular';
        //font-family: "DM Sans";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

    }
  }
}

@media screen and (min-width: 768px) and (max-width: 1320px) {
  .startChallenge {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 24px;
    gap: 24px;
    //align-items: center;
    height: calc(100vh - 120px);
    overflow-y: auto;
    >div:first-of-type{
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      gap: 63px;
      justify-content: space-between;
      >div:first-of-type {
        display: flex;
        flex-direction: column;
        gap: 63px;
      };
      >div:last-of-type{
        max-width: 217px;
        max-height: 217px;
        >img{
          width: 100%;
          height: 100%;
        }
      }
    }
    >div:last-of-type{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      gap: 40px;
      >div:last-of-type {
        max-width: 355px;
        width: 100%;
      }
    }
  }
  .challenge {
    max-width: 490px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    >p:first-of-type{
      color: var(--N-9, #17151C);
      //font-family: 'aeonikregular';
      //font-family: "DM Sans";
      font-size: 32px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin: 0;
    }
    >p:last-of-type{
      color: var(--N-9, #17151C);
      //font-family: 'aeonikregular';
      //font-family: "DM Sans";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 30px;
      margin: 0;
    }
  }
}
@media screen and (max-width: 768px) {
  .startChallenge {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 0 24px;
    gap: 24px;
    //align-items: center;
    //height: calc(100vh - 120px);
    overflow-y: scroll;
    >div:first-of-type{
      display: flex;
      flex-direction: column-reverse;
      align-items: flex-start;
      gap: 0;
      justify-content: space-between;
      >div:first-of-type {
        display: flex;
        flex-direction: column;
        gap: 48px;
      };
      >div:last-of-type{
        max-width: 234px;
        max-height: 234px;
        >img{
          width: 100%;
          height: 100%;
        }
      }
    }
    >div:last-of-type{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      gap: 32px;
      >div:last-of-type {
        //object-fit: contain;
        width: auto;
      }
    }
  }
  .challenge {
    max-width: 490px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    >p:first-of-type{
      color: var(--N-9, #17151C);
      //font-family: 'aeonikregular';
      //font-family: "DM Sans";
      font-size: 32px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin: 0;
    }
    >p:last-of-type{
      color: var(--N-9, #17151C);
      //font-family: 'aeonikregular';
      //font-family: "DM Sans";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 30px;
      margin: 0;
    }
  }
  .form {
    min-width: 0;
    display: flex;
    flex-direction: column;
    gap: 24px;

  }
}
