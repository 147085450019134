.summary {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 24px 74px 24px 24px;
  >div:first-of-type{
    align-self: flex-start;
    max-width: 862px;
    width: 100%;
    box-sizing: border-box;
    overflow: scroll;
    height: calc(100vh - 171px);
    >div{
      >div{
        >div:first-of-type {
          display: flex;
          justify-content: space-between;
          padding: 10px 16px;
          > p{
            margin: 0;
            color: #17151C;
            //font-family: 'aeonikregular';
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 30px; /* 187.5% */
          };
        }
        >div:last-of-type{
          > ul {
            display: flex;
            flex-direction: column;
            gap: 14px;
            margin: 0;
            padding: 10px 16px;
            >.file {
              > p:last-of-type {
                display: flex;
                align-items: center;
                padding: 8px 16px;
                margin: 0;
                gap: 10px;
                border-radius: 20px;
                opacity: 0.9;
                background: #FFF;
                color: #7956D8 !important;
                justify-content: space-between;
                > img {
                  width: 24px;
                }
              }
            }
            > li {
              min-height: 30px;
              display: flex;
              justify-content: space-between;
              align-items: center;
              list-style-type: none;
              height: 100%;
              > p {
                margin: 0;
                white-space: pre;
                text-overflow: ellipsis;
                overflow: hidden;
              }
              > p:last-of-type {
                //font-family: 'aeonikregular';
                //color: var(--N-9, #17151C);
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                text-align: right;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 30px;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                width: 400px;
                >div{
                  display: flex;
                  flex-direction: column;
                }
                >p{
                  margin: 0;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  overflow: hidden;
                  width: 400px;
                  gap: 4px;
                  >p{
                    margin: 0;
                    display: flex;
                    flex-direction: column;
                    gap: 7px;
                    >p{
                      margin: 0;
                    }
                  }
                }
              }
              >p:first-of-type{
                //display: flex;
                //flex-direction: column;
                //gap: 14px;
                //color: #59565C;
                //font-size: 16px;
                //font-style: normal;
                //font-weight: 400;
                //line-height: 22px;
                //margin: 0;
                width: 400px;
                //white-space: nowrap;
                //text-overflow: ellipsis;
                //overflow: hidden;
                >p {
                  width: 400px;
                  white-space: pre;
                  text-overflow: ellipsis;
                  overflow: hidden;
                }
              }
            }
          }
        }
      }
    }
  }
 >div:first-of-type::-webkit-scrollbar {
   display: none;
 }
}
.getPdf {
  >div:first-of-type {
    > img {
      width: 357px;
    }
  }
  >div:last-of-type {
    > div{
      display: flex;
      flex-direction: column;
      width: 362px;
      padding: 16px;
      align-items: center;
      gap: 20px;
      border-radius: 10px 10px 10px 0;
      background: #F6F0FF;
      > p {
        color:  #3A3542;
        //font-family: 'aeonikregular';
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        margin: 0;
      };
      > button {
        min-width: 237px;
        color: #FFF;
        padding: 10px;
        text-align: center;
        //font-family: 'aeonikregular';
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px;
        border-radius: 5px;
        background: #7956D8;
        border: none;
        cursor: pointer
      }
    }

  }
}
.disabled {
  opacity: .6;
}
@media screen and (min-width: 768px) and (max-width: 1320px){
  .summary {
    height: 100vh;
    flex-direction: column-reverse;
    justify-content: flex-end;
    padding: 24px;
    >div:first-of-type {
      max-height: calc(100vh - 470px);
      display: flex;
      overflow-y: scroll;
    }
    >div:last-of-type{
      width: 100%;
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      align-items: center;
    }
  }
}
@media screen and (max-width: 768px){
  .summary {
    height: calc(100vh - 120px);
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    padding: 24px;
    gap: 48px;
    >div:first-of-type {
      max-height: calc(100vh - 470px);
      display: flex;
      overflow-y: scroll;
    }
    >div:last-of-type{
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      > div{
        width: 100%;
        display: flex;
        box-sizing: border-box;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
