.confirmationModal {
  width: 100%;
  text-align: center;
  border-radius: 20px;
  background: #FFF;
}

.largeConfirmationModal {
  max-width: 661px;
  width: 100%;
}

.headerStyles {
  color: #17151C;
  padding-bottom: 16px;
  padding-top: 16px;
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
  >img {
    cursor: pointer;
  }
}

.largeHeader {
  border-bottom: 1px solid #DAD6E5;
  padding: 15px 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
}

.bodyStylesForSmall {
  letter-spacing: -0.08px;
  padding: 16px 24px 24px;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #59565C;
}

.bodyStyles {
  max-height: 600px;
  //overflow: auto;
  display: flex;
  flex-direction: column;
}

.bodyStyles::-webkit-scrollbar {
  display: none;
}

.footerStyles {
  padding: 0 100px 24px;
  > button:first-of-type:not(last-of-type) {
  }
  > button {
    flex: 1 1 0;
  }
  display: flex;
  justify-content: center;
}

.skipButton {
  margin-top: 6px;
  flex-basis: 100%;
  color: #4e73ff;
  float: right;
}

.headerText{
  width: 100%;
  text-align: center;
  color: #17151C;
  font-size: 18px;
  line-height: 26px;
  font-weight: 700;
}

@media screen and (max-width: 768px){
  .largeConfirmationModal {
    min-width: 375px;
    width: 100%;
    box-sizing: border-box;
  }
}
@media screen and (max-width: 450px){
  .largeConfirmationModal {
    margin: 0 !important;
  }
}
