.challengeSteps {
  display: flex;
  flex-direction: row;
  padding: 24px;
  justify-content: space-between;
  align-items: flex-start;
  box-sizing: border-box;
  > div:nth-child(1) {
    border-radius: 9px;
    background: #FFFFFF;
    min-width: 320px;
    max-width: 400px;
    overflow-y: auto;
    /* padding-top: 0; */
    flex-direction: column;
    align-items: self-end;
    overflow-x: hidden;
    /* margin: 0 0 32px 0; */
    height: calc(100vh - 171px);

  >div{
    >div{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 10px 16px;
      max-width: 400px;

      >div:first-of-type{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        >p{
        margin: 0;
        color: #17151C;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 30px;
      }
        >img {
          width: 24px;
        }
      }
    }
  }
    .careReceipients {
      > ul {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 4px;
        padding: 0;
        margin: 0;
        > li {
          min-height: 30px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          list-style-type: none;
          height: 100%;
          > p {
            margin: 0;
            white-space: pre;
            text-overflow: ellipsis;
            overflow: hidden;
          }
          >p:first-of-type{
            width: 225px;
            >p {
              max-width: 200px;
              width: 100%;
              white-space: pre;
              text-overflow: ellipsis;
              overflow: hidden;
            }
          }
          >div{
            display: flex;
            flex-direction: column;
            //align-items: flex-end;
            justify-content: flex-end;
            color:#17151C;
            text-align: right;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 30px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            width: 225px;
          }
          > p:last-of-type {
            display: flex;
            //flex-direction: column;
            //align-items: flex-end;
            justify-content: flex-end;
            color:#17151C;
            text-align: right;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 30px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            width: 225px;
            >div{
              display: flex;
              flex-direction: column;
              align-items: flex-end;
              width: 300px;
              >span{
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                width: 225px;
                //box-sizing: border-box;
                //width: 100%;
              }
            }
            >img{
              width: 24px;
            }
            >p{
              margin: 0;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
              width: 225px;
              gap: 4px;
              >p{
                margin: 0;
                display: flex;
                flex-direction: column;
                gap: 7px;
                >p{
                  margin: 0;
                }
              }
            }
          }
        }

      }
    }
  }
  > div:nth-child(2) {
    display: flex;
    flex-direction: column;
    align-items: self-end;
    overflow-x: hidden;
    //margin: 0 0 32px 0;
    height: calc(100vh - 165px);
    > div {
      display: flex;
      flex-direction: column-reverse;
      width: 100%;
      overflow-y: auto;
      overflow-x: hidden;
      scroll-behavior: smooth;

      > ul {
        width: 524px;
        padding: 0;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 24px;
        margin-right: 12px;
        >span{
          max-width: 450px;
          border-radius: 10px 10px 10px 0;
          background: #F6F0FF;
          padding: 16px;
          align-items: flex-start;
          margin: 0;
        }
        >.odd  {
          display: flex;
          flex-direction: row;
          gap: 10px;
          justify-content: center;
          >img {
            //width: 48px;
            height: 48px;
          }
          >.checkbox {
            display: flex;
            align-items: center;
            gap: 8px;
            width: 492px;
            color: #17151C;
            //font-family: 'aeonikregular';
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
            border-radius: 10px ;
            background: #F6F0FF;
              > .checkboxStyles {
                //align-self: flex-start;
                cursor: pointer;
              }
          }
          >p {
            width: 396px;
            border-radius: 10px 10px 10px 0;
            background: #F6F0FF;
            padding: 16px;
            align-items: flex-start;
            //font-family: 'aeonikregular';
            margin: 0;
            > div {
              > p {
                margin: 0;
                color:  #7956D8;
                //font-family: 'aeonikregular';
                //font-family: "DM Sans";
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 22px;
              };
            }
          }
        }
        >.returnQuestion {
          display: flex;
          flex-direction: row;
          gap: 10px;
          >p {
            min-width: 396px;
            border-radius: 10px 10px 10px 0;
            background: #F6F0FF !important;
            padding: 16px;
            align-items: flex-start;
            //font-family: 'aeonikregular';
            margin: 0;
            > div {
              > p {
                margin: 0;
                color: var(--B-5, #7956D8);
                //font-family: 'aeonikregular';
                //font-family: "DM Sans";
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 22px;
              };
            }
          }
        }

        > .even {
          display: flex;
          align-items: flex-start;
          border-radius: 10px 10px 0 10px;
          background: var(--N-2, #F4F3F7);
          padding: 16px;
          min-width: 295px;
          align-self: flex-end;
          > p {
            //padding: 8px;
            margin: 0;
            display: flex;
            align-items: center;
            justify-content: space-between;
            max-width: 310px;
            width: 100%;
            min-height: 22px;
            word-break: break-word;
            //font-family: 'aeonikregular';
            >.euro {
              width: 16px;
              margin-right: auto;
            }
            >img {
              height: 22px;
            }
            >img:hover{
              cursor: pointer;
            }
            >p{
              margin: 0;
              display: flex;
              flex-direction: column;
              width: 100%;
              gap: 10px;
              >p{
                margin: 0;
                display: flex;
                justify-content: space-between;
                >span:last-of-type{
                  color: #3A3542;
                  text-align: right;
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 700;
                  line-height: 22px;
                }
              }
            }
          }

        }
        >.finalQuestion {
          display: flex;
          gap: 10px;
          >p{
            min-width: 396px;
            border-radius: 10px 10px 10px 0;
            background: var(--B-1, #F6F0FF);
            padding: 16px;
            align-items: flex-start;
            //font-family: "aeonikregular";
            margin: 0;
          }
        }
      }
      //::-webkit-scrollbar-track {
      //  background-color:red;
      //}
    }
    >div::-webkit-scrollbar {
      width: .3em;
      margin-left: 10px;
      background-clip: padding-box;
    }
    >div::-webkit-scrollbar-thumb {
      background-color: #FFFFFF;
      border-radius: 10px;
      background-clip: padding-box;
    }

  }

  > div:nth-child(2)::-webkit-scrollbar,  > div:nth-child(1)::-webkit-scrollbar{
    display: none;
  }
  > div:nth-child(3) {
    position: relative;
    display: flex;
    flex-direction: column;
    > div {
       > img {
             //margin-top: 23%;
             width: 288px;
       }
      >button{
        width: 48px;
        height: 48px;
        background-color: #7956D8;
        border-radius: 100%;
        border: none;
        outline: none;
        position: fixed;
        bottom: 90px;
        right: 40px;
        >img{
          transform: rotate(90deg);
        }
      }
      >.arrowUp{
        >img{
          transform: rotate(-90deg);
        }
      }
    }
    >.messageRobot{
      position: relative;
      border-radius: 11.756px;
      background: var(--Main-Lilac-Violet, #7956D8);
      //top: -5%;
      left: -6%;
      width: 270px;
      height: 165px;
      >img{
        width: 32px;
        /*         transform: scale(1, 1) translate(16%, -13%); */
        position: absolute;
        bottom: -13%;
        left: 16%;
      }
      >p{
        color: #FFF;
        //font-family: Aeonik;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin:0;
        padding: 16px;
      }
    }
    //>.messageRobot::after {
    //  content: "";
    //  position: absolute;
    //  top: -20px;
    //  right: 30px;
    //  width: 32px;
    //  height: 25px;
    //  //background-color: transparent;
    //  //background-image: url('../../../../icons/vector.svg');
    //  mask-size: contain;
    //  mask-repeat: no-repeat;
    //}
    > .infoRobot {
      border-radius: 11.756px;
      background: #F6F0FF;
      //top: -5%;
      left: -6%;
      width: 270px;
      //height: 165px;
      >p{
        margin: 0;
        padding: 16px;
      }
    }
  }
}
.rotate {
  transform: rotate(180deg);
}

.certificate {
  >ul {
    .file {
      > p:last-of-type {
        > p {
          margin: 0;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
        display: flex;
        align-items: center;
        padding: 8px 16px;
        margin: 0;
        gap: 10px;
        border-radius: 20px;
        opacity: 0.9;
        background: var(--N-0, #FFF);
        color: #7956D8 !important;
        justify-content: space-between;
        > img {
          width: 24px;
        }
      }
    }
  }
}

.fileStyle {
  padding: 8px !important;
  >p {
    display: flex;
    align-items: center;
    padding: 8px 16px;
    margin: 0;
    gap: 10px;
    border-radius: 20px;
    width: auto !important;
    background: var(--N-0, #FFF);
    color: #7956D8;
  }
}

.hideList {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

    div{
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 100%;
      gap: 10px;
    }
    textarea{
      border: none;
      resize: none;
      background: inherit;
      outline: none;
      color: var(--N-8, #3A3542);
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      /* height: 24px; */
      min-width: 385px;
      width: 100%;
      min-height: 62px;
    }
}
.borderLi {
  border: 1px solid #7956D8;
  flex-direction: row;
  align-items: flex-end !important;
}
.error {
  border: 1px solid var(--R-6, #F14055);
}
.careCertificate{
  >p:last-child {
    display: flex;
    align-items: center;
    padding: 8px 16px;
    margin: 0;
    gap: 10px;
    border-radius: 20px;
    opacity: 0.9;
    background: var(--N-0, #FFF);
  }
}

.details {
  display: flex;
  flex-direction: column;
  //padding: 10px 24px;
  gap: 16px;
  >div{
    min-width: 400px;
    width: 100%;
    border-radius: 9px;
    background: #F7F7F7;
  }
}
@media screen and (min-width: 768px) and (max-width: 1320px){
  .challengeSteps {
    > div:nth-child(2){
      display: flex;
      flex-direction: column;
      width: 100%;
      >div:first-of-type{
       align-self: center;
        > button {
          background-color: #7956D8;
          border-radius: 100%;
          width: 48px;
          height: 48px;
          outline: none;
          border: none;
          position: fixed;
          bottom: 90px;
          right: 24px;
          >img{
            transform: rotate(90deg);
          }
        }

      }
      >div:last-of-type{
        .borderLi {
          border: 1px solid #7956D8;
          flex-direction: row;
          align-items: flex-end !important;
        }
        >ul {
          width: 100%;
          .even{
            //max-width: 396px;
            //width: 100%;
            word-break: break-word;
            box-sizing: border-box;
            > textArea{
              width: 75%;
              height: auto;
            }
            >p{
              max-width: 396px;
              width: 100%;
              box-sizing: border-box;
            }
          }
          >.returnQuestion {
            >p {
              max-width: 396px;
            }
          }
        }
      }
    }
    > div:nth-child(3){
      display: none;
    }
  }
  .details{
    display: none;
  }
}

@media screen and (max-width: 768px){
  .challengeSteps {
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    >button {
      display: flex;
      width: 192px;
      //padding: 9px 20px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 8px;
      border-radius: 100px;
      outline: none;
      border: none;
      background: var(--N-2, #F4F3F7);
      margin-top: 20px;
      >p{
        color: #7956D8;
      }
    }
    > div:nth-child(2){
      display: flex;
      flex-direction: column;
      align-items: center;
      min-width: 320px;
      width: 100%;
      height: calc(100vh - 230px);

      >div:first-of-type{
        align-self: center;
        min-width: 320px;
        width: 100%;
      }
      >div:last-of-type {
        width: 100%;
        > button {
          background-color: #7956D8;
          border-radius: 100%;
          width: 32px;
          height: 32px;
          outline: none;
          border: none;
          position: fixed;
          bottom: 90px;
          right: 24px;
          >img{
            width: 20px;
            transform: rotate(90deg);
          }
        }
        >ul {
          width: 100%;
          .odd{
            >p{
              width: 100%;
            }
          }
          .borderLi {
            border: 1px solid #7956D8;
            flex-direction: column;
            align-items: flex-end !important;
            max-width: 276px;
            width: 100%;
          }
          .even{
            max-width: 276px;
            width: 100%;
            box-sizing: border-box;
            word-break: break-word;
              > textArea{
                min-width: 0;
                max-width: 276px;
                width: 100%;
                height: auto;
                box-sizing: border-box;
              }
            >p{
              max-width: 276px;
              width: 100%;
              box-sizing: border-box;
            }
          }
        }
      }
    }
    > div:nth-child(3){
      display: none;
    }

  }
  .details{
    display: none;
  }
}
@media screen and (max-width: 450px) {
  .challengeSteps {
    height: calc(100vh - 157px);
    box-sizing: border-box;
  }
}