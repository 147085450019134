.button {
  border-radius: 5px;
  border: solid 1px transparent;
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 1000px 1px #fff inset;
  color: #7956D8;
  font-size: 16px;
  padding: 10px 32px;
  cursor: pointer;
  align-items: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: 20px;
}

.primary {
  background-color: #7956D8;
  color: #ffffff;
  background-clip: unset;
  box-shadow: none;
  //width: 130px;
}

.medium {
  font-size: 16px;
  line-height: 20px;
  min-width: 210px;
  font-weight: 500;
}

.large {
  min-width: 126px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.disabled {
  opacity: .5;
  background-image: none;
  color: #FFFFFF;
}

.largeGrayDisabled {
  background-color: #f4f3f7;
  background-image: none;
  color: red;
}

.gray {
  background-color: #f4f3f7;
  color: #7956D8;
  background-image: none;
  box-shadow: none;
  background-clip: inherit;
}

.text {
  margin-top: 32px;
  background: none;
  padding: 0;
  outline: none;
  border: none;
}

.oval {
  border-radius: 100%;
  padding: 13px 12px;
  background: inherit;
  border: 1px solid #7956D8;;
}

.warningOutlined {
  max-width: 173px;
  padding: 12px 0;
  //margin-left: 24px;
  box-sizing: border-box;
  min-width: 173px;
  background-color: #FFFFFF;
  color: #F14055;
  border: 1px solid #F14055;
  border-radius: 5px;
  text-align: center;
  font-weight: 400;
  font-size: 16px;
  height: 42px;
  //line-height: 22px;
}

.smallPrimaryBg {
  max-width: 173px;
  width: 100%;
  background: #7956D8;
  border-radius: 5px;
  border: none;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  height: 42px;
  text-align: center;
  color: #F6F0FF;
  padding: 10px 0;
}
.primaryOutlined {
  width: 130px;
  padding: 10px 0;
  box-sizing: border-box;
  //min-width: 173px;
  height: 42px;
  background-color: #FFFFFF;
  color: #7956D8;
  outline: 1px solid #7956D8;
  border: none;
  border-radius: 5px;
  text-align: center;
  font-weight: 400;
  font-size: 16px;
}

.largePrimary {
  background-color: #7956D8;
  border-radius: 5px;
  max-width: 371px;
  width: 100%;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #F6F0FF;
  padding: 10px 0;
  border: none;
}

.largePrimaryDisabled {
  opacity: 0.3;
  background-color: #7956D8;
  border-radius: 5px;
  max-width: 371px;
  width: 100%;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #F6F0FF;
  padding: 12px 0;
}

//
//.smallPrimaryBg {
//  max-width: 173px;
//  width: 100%;
//  background: #7956D8;
//  border-radius: 5px;
//  font-style: normal;
//  font-weight: 400;
//  font-size: 16px;
//  line-height: 22px;
//  text-align: center;
//  color: #F6F0FF;
//  padding: 12px 0;
//}

.smallPrimaryNotBg {
  max-width: 173px;
  width: 100%;
  background: #FFFFFF;
  border: 1px solid #7956D8;
  border-radius: 5px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #7956D8;
  margin-right: 24px;
  padding: 12px 0;
}

.grayCancelText {
  max-width: 173px;
  width: 100%;
  background: #F4F3F7;
  border-radius: 5px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #7956D8;
  padding: 12px 0;
  margin-right: 24px;
}

.grayConfirm {
  max-width: 173px;
  width: 100%;
  background: #F4F3F7;
  border-radius: 5px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #F14055;
  padding: 12px 0;
}

.largeGray {
  background: #F4F3F7;
  border-radius: 5px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #7956D8;
  max-width: 371px;
  width: 100%;
  padding: 12px 0;
}

.apply {
  background: #7956D8;
  opacity: 1;
  color: #F4F3F7;
  border-radius: 5px;
  height: 48px;
}

.redText {
  color: #F14055;
  font-weight: 500;
}

.applyDisabled {
  background: #7956D8;
  opacity: 0.3;
  border-radius: 5px;
  height: 48px;
}
.linkBtn {
  margin-top: 0;
  background: none;
  padding: 0;
  outline: none;
  border: none;
  color: #7956D8;
}