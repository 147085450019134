.buttonStyles {
  //position: absolute;
  //top: 20px;
  //right: 20px;
  //margin: 0;
  //cursor: pointer;
  background-color: #FFFFFF;
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
  color: #59565C;
  padding: 4px 8px;
  border: 1px solid #DAD6E5;
  border-radius: 5px;

  > img {
    margin-left: 8px;
  }
  display: flex;
  align-items: center;
}
