.contactPerson {
  width: 430px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  border-radius: 26.924px;
  border: 1px solid var(--N-3, #DAD6E5);
  background: var(--N-0, #FFF);
  padding: 22px 40px;
  >p {
    margin: 0;
    color: #121037;
    //font-family: "aeonikregular";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  >div {
    display: flex;
    flex-direction: row;
    gap: 32px;
    >div:last-of-type{
      display: flex;
      flex-direction: column;
      gap: 11px;
      >p{
        margin:0;
      }
      >p:nth-of-type(1){
        color: #121037;
        //font-family: "aeonikregular";
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-bottom: -7px;
      }
      >p:nth-of-type(2) {
        color: #121037;
        //font-family: 'aeonikregular';
        //font-family: "DM Sans";
        font-size: 12px;
        font-style: normal;
        font-weight: 250;
        line-height: normal;
      }
      >p:nth-of-type(3) {
        color: var(--Main-Lilac-Violet, #7956D8);
        //font-family: 'aeonikregular';
        //font-family: "DM Sans";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      >p:nth-of-type(4) {
        color: #121037;
        //font-family: 'aeonikregular';
        //font-family: "DM Sans";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

    }
  }
}