.profile {
  display: flex;
  flex-direction: row;
  gap: 79px;
  position: relative;
  height: calc(100vh - 67px);
  overflow-y: scroll;
}

.formInfoWrapper {
  padding-top: 26px;
  padding-left: 35px;
  max-width: 629px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  >div:first-of-type{
    >div:first-of-type{
      display: flex;
      flex-direction: column;
      gap: 16px;
      >h1 {
        color: var(--N-9, #17151C);
        //font-family: Aeonik;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 38px;
        margin-top: 0;
      }
      >p {
        color: var(--N-9, #17151C);
        //font-family: Aeonik;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        margin: 0;
      }
      > button {
        max-width: 218px;
        padding: 10px;
        border-radius: 5px;
        background: var(--Main-Lilac-Violet, #7956D8);
        border: none;
        color: var(--N-0, #FFF);
        text-align: center;
        //font-family: Aeonik;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px;
        cursor: pointer;
      }
    }
    >div:last-of-type{
      display: none;
    }
  }
}
.forms {
  padding: 16px;
  border-radius: 9px;
  background: var(--B-1, #F6F0FF);
  display: flex;
  flex-direction: column;
  gap: 8px;
  div:first-of-type {
    display: flex;
    justify-content: space-between;
    >p {
      color: var(--N-9, #17151C);
      //font-family: Aeonik;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 22px;
      margin: 0;
    }
    >img {
      cursor: pointer;
    }
  }
  >ul {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 0;
    margin: 0;
    > li {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 8px 8px 8px 16px;
      gap: 8px;
      height: 48px;
      border-radius: 5px;
      background: var(--N-0, #FFF);
      box-sizing: border-box;
      >p:first-of-type {
        color: var(--N-9, #17151C);
        margin: 0;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        //word-break: break-word;
      }
      >p:last-of-type{
        display: flex;
        gap: 8px;
        margin: 0;
        max-width: 220px;
        >button:first-of-type {
          text-decoration: none;
          font-size: 14px;
          font-style: normal;
          //font-family: Aeonik;
          font-weight: 400;
          line-height: 18px;
          padding: 6px 18px;
          text-align: center;
          border-radius: 5px;
          border: 1px solid var(--Main-Lilac-Violet, #7956D8);
          background: var(--N-0, #FFF);
          color: var(--B-6, #7956D8);
          cursor: pointer;
          box-sizing: border-box;
          display: block;
          height: 32px;
          //max-width: 140px;
          //min-width: 100px;
          //width: 100%;
          white-space: pre;
          text-overflow: ellipsis;
          overflow: hidden;
        }
        >button:last-of-type {
          font-size: 14px;
          font-style: normal;
          margin-left: auto;
          font-weight: 400;
          line-height: 18px;
          padding: 7px 18px;
          text-align: center;
          border-radius: 5px;
          border: none;
          background: var(--Main-Lilac-Violet, #7956D8);
          color: var(--N-0, #FFF);
          cursor: pointer;
          height: 32px;
          //max-width: 140px;
          //min-width: 100px;
          //width: 100%;
          box-sizing: border-box;
          white-space: pre;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }


    }
  }
}
.disabled {
  opacity: .6;
}
.rotate {
  transform: rotate(180deg);
}

.robbot{
  position: sticky;
  top: 0;
  right: 0;
  >img {
    width: 476px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1320px){
  //height: calc(100vh - 67px);
  .formInfoWrapper {
    min-width: 768px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 24px;
    >div:first-of-type{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      >div:last-of-type{
        display: flex;
        >img{
          width: 217px;
        }
      }
    }
  }
  .robbot{
 display: none;
  }
}
@media screen and (max-width: 768px){
  .profile{
    justify-content: center;
    overflow-y: clip;
  }
  .formInfoWrapper {
    max-width: 768px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
    padding: 24px;
    >div:first-of-type{
      width: 100%;
      display: flex;
      flex-direction: column-reverse;
      justify-content: space-between;
      align-items: center;
      >div:first-of-type{
        justify-content: center;
        align-items: center;
        > h1{
          margin: 0;
        }
        >button {
          width: 100%;
        }
      }

      >div:last-of-type{
        display: flex;
        >img{
          width: 217px;
        }
      }
    }
  }
  .forms {
    width: 100%;
    box-sizing: border-box;
  }
  .robbot{
    display: none;
  }
}
