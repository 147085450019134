.countryPicker {
  display: flex;
  cursor: pointer;
  align-items: center;
  align-self: center;
}

.countryPicker > img:first-of-type {
  width: 22px;
  height: 15px;
  margin: 0 4px 0 12px;
}

.countryPicker > img:last-of-type {
  margin-right: 12px;
}
