.accountDropDown {
  display: flex;
  align-items: center;
  position: relative;
  //padding: 8px 0 8px 24px;
  padding: 0;
  border: none;
  background-color: #ffffff;
  cursor: pointer;
  >div{
    display: flex;
    flex-direction: column;
    align-items: center;
    >img{
      width: 40px;
      position: relative;
      top: 5px;
    }
    >.userType {
      z-index: 9999;
      //position: absolute;
      padding: 1px 6px;
      background-color: #7956D8;
      border-radius: 3px;
      color: #FFF;
      //font-family: Aeonik;
      font-size: 10px;
      font-weight: 400;
      line-height: normal;
      //left: 4px;
      //bottom: -3.6px;
    }
  }
  >img:last-of-type {
    margin-left: 16px;
    width: 30px;
  }
}

.nameSurnameDateWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: start;
  >span:first-of-type {
    max-width: 220px;
    width: 100%;
    margin-bottom: 4px;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #17151C;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

  }
  >span:last-of-type {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #A29DB0;
  }
  margin-left: 16px;
}


